import React, { lazy, Suspense } from "react";
import ScrollToTopButton from "../../components/ScrollToTopButton";

import background01 from "../../assets/images/backgroundImage01.png";
import background02 from "../../assets/images/backgroundImage02.png";
import background03 from "../../assets/images/backgroundImage03.png";

const LazyAbout = lazy(() => import("../About"));
const LazyWhyChooseUs = lazy(() => import("../WhyChooseUs"));
const LazyServices = lazy(() => import("../Services"));
// const LazyProjects = lazy(() => import("../Projects"));
const LazyContacts = lazy(() => import("../Contacts"));
const LazyFooter = lazy(() => import("../../components/Footer"));


const styles = {
    spacerBanner: {
        width: "100vw",
        height: "200px",
        backgroundColor: "#F98328",
        color: "white",
        textAlign: "center",
        verticalAlign: "middle",
        display: "table-cell"
    },
    bannerText: {
        color: "white",
    },
    spacerAbout: {
        width: "inherit",
        height: "auto",
    },
    backGroundImage: {
        width: "100%",
        height: "auto",
    },
}

export default function MainPage() {
    return (
        <div>
            <ScrollToTopButton />
            <section style={styles.spacerBanner}>
                <h2 style={styles.bannerText}>We Capture the Reality to Build Your Dreams!</h2>
            </section>
            <section id="about" style={styles.spacerAbout}>
                <img style={styles.backGroundImage} src={background01} alt="beautiful interior stone wall" />
            </section>
            <Suspense fallback={<div>Loading About...</div>}>
                <LazyAbout />
            </Suspense>
            <section style={{...styles.spacerBanner, height: "50px"}}>
            </section>
            <div></div>
            <section style={styles.spacerBanner}>
                <Suspense fallback={<div>Loading Why Choose Us...</div>}>
                    <LazyWhyChooseUs />
                </Suspense>
            </section>
            <section id="services" style={styles.spacerAbout}>
                <img style={{ ...styles.backGroundImage, padding: "75px" }} src={background02} alt="line drawing of a man standing behind a laser tripod scanning a structure" />
            </section>
            <Suspense fallback={<div>Loading Services...</div>}>
                <LazyServices />
            </Suspense>
            <section style={{...styles.spacerBanner, height: "150px"}}>
            </section>
            <div></div>
            <section id="contacts" style={styles.spacerBanner}>
                <img style={styles.backGroundImage} src={background03} alt="wood panel cladding of walls and ceilings" />
            </section>
            <Suspense fallback={<div>Loading Contacts...</div>}>
                <LazyContacts />
            </Suspense>
            <Suspense fallback={<div>Loading Footer...</div>}>
                <LazyFooter />
            </Suspense>

        </div>
    )
}