import React from 'react';
import Header from './components/Header';
import MainPage from './pages/MainPage';
import './App.css';

function App() {
  return (
    <>
    <Header/>
    <MainPage/>
    </>
  );
}

export default App;
