import React, { useState } from "react";

const styles = {
    navUl:{
        flexDirection:"row"
    },
    navLi:{
        color:"white",
        padding:"5px",
        // border:"solid thin",
        borderColor:"white", 
        margin:"5px",
        textDecoration: "none"
    }
}
export default function AppNavbar() {
    const [activeLink, setActiveLink] = useState(null);

    const handleLinkClick = (index) => {
        setActiveLink(index);
    };

    return (
        <nav className="navbar navbar-fixed-top">
            <div className="container-fluid">
                <ul className="navbar-nav" style={styles.navUl}>
                    <li>
                        <a href="#about" className={`nav-item ${activeLink === 0 ? "active" : ""}`}  style={styles.navLi}
                            onClick={() => handleLinkClick(0)}>About Us</a>
                    </li>
                    <li>
                        <a href="#services" className={`nav-item ${activeLink === 1 ? "active" : ""}`}  style={styles.navLi}
                            onClick={() => handleLinkClick(1)}>Services</a>
                    </li>
                    <li>
                        <a href="#contacts" className={`nav-item ${activeLink === 3 ? "active" : ""}`}  style={styles.navLi}
                            onClick={() => handleLinkClick(3)}>Contacts</a>
                    </li>
                </ul>
            </div>
        </nav>
    );
};
