import React, { useState, useEffect } from "react";
import AppNavbar from "../AppNavbar";
import logoSmlLft from "../../assets/images/logo_sml_left_orng.png";
import logoSmlerLft from "../../assets/images/logo_smler_left_orng.png";

const styles = {
  headerContainer: {
    display: "flex",
    padding: "15px", // Increase padding for better visibility
    backgroundColor: "#F98328",
    color: "white",
    border: "solid",
    borderColor: "#F98328",
    position: "sticky",
    top: 0,
    zIndex: 1000,
    transition: "all 0.7s ease", // Add transition for smooth effect
  },
  headerContainerLeft: {
    flexGrow: 3,
    transition: "all 0.7s ease",
  },
  headerContainerRight: {
    display: "grid",
    placeItems: "center",
    transition: "all 0.7s ease",
  },
  shrunkHeader: {
    padding: "0px",
    paddingLeft: "10px", // Adjust padding for the shrunk header
  },
  shrunkLeft: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    paddingLeft: "10px",
    flexGrow: 1,
  },
  logo: {
    maxWidth: "100%", // Set max width for the logo
    transition: "all 0.7s ease",
  },
  hidden: {
    display: "none",
  },
  smaller:{
    transform: "scale(0.85)",
    transition: "all 0.7s ease",
    marginRight: "10px"
  }
};

export default function Header() {
  const [isShrunk, setShrunk] = useState(false);

  
  const handleScroll = () => {
    if (window.scrollY > .01) {
      setShrunk(true);
    } else {
      setShrunk(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      style={{
        ...styles.headerContainer,
        ...(isShrunk && styles.shrunkHeader),
      }}
    >
      <div
        className="headerContainerLeft"
        style={{
          ...styles.headerContainerLeft,
          ...(isShrunk && styles.shrunkLeft),
        }}
      >
        <h1>{isShrunk ? "DINOZIO" : "DINOZIO.DESIGN"}</h1>
        {isShrunk ? null : (
          <>
            <h5>DIGITAL SURVEY & LASER TEMPLATING</h5>
          </>
        )}
        <AppNavbar />
      </div>
      <div
        className="headerContainerRight"
        style={{
          ...styles.headerContainerRight,
          ...(isShrunk && styles.shrunkRight),
        }}
      >
        <a href="" target="_blank" rel="noopener noreferrer" >
          <img
            src={isShrunk ? logoSmlerLft : logoSmlLft}
            alt="Dinozio Design Logo"
            style={{ ...styles.logo, ...(isShrunk && styles.smaller) }}
          ></img>
        </a>
      </div>
    </div>
  );
}
